import React from "react";
import Bomberman from "../components/PageSwitch/pages/Game/Bomberman.page";
import { SSRGuard } from "../components/SSRGuard/SSRGuard";
const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <React.Fragment>
            <SSRGuard>
                <Bomberman />
            </SSRGuard>
        </React.Fragment>
    );
};

export default Page;
